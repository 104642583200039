import { createApp } from 'vue';
import { VuesticPlugin } from 'vuestic-ui'; 
import 'vuestic-ui/dist/vuestic-ui.css';
import { VaCard, VaList, VaListItem, VaListItemSection, VaListLabel, VaAvatar, VaListItemLabel, VaButton, VaAlert, VaInput, VaInnerLoading, VaSelect, VaPopover, VaFileUpload, VaBadge} from 'vuestic-ui';

import App from './App.vue';
import router from './router.js';

import BaseCard from './components/BaseCard.vue';

const app = createApp(App);

app.use(router);
app.use(VuesticPlugin);
app.component("base-card", BaseCard);

app.component("va-card", VaCard);
app.component("va-list", VaList);
app.component("va-list-label", VaListLabel);
app.component("va-list-item", VaListItem);
app.component("va-list-item-section", VaListItemSection);
app.component("va-avatar", VaAvatar);
app.component("va-list-item-label", VaListItemLabel);
app.component("va-button", VaButton);
app.component("va-alert", VaAlert);
app.component("va-input", VaInput);
app.component("va-inner-loading", VaInnerLoading);
app.component("va-select", VaSelect);
app.component("va-popover", VaPopover);
app.component("va-file-upload", VaFileUpload);
app.component("va-badge", VaBadge)

app.mount('#app');
