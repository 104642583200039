export default {
    methods: {
    async candidateList() {
        this.all_candidates = [];
        const response = await fetch(process.env.VUE_APP_BASE_URL + "getcandidates");
        const response_data = await response.json();
        if (!response.ok) {
                this.error = new Error('Failed to fetch candidates');
                return;
        }
        else{
            for(var i = 0; i < response_data.length; i++){
                this.all_candidates.push(response_data[i]);
            }
            if(this.all_candidates == []){
                this.message = "Add the candidates first!"
            }
        }
    }
    }
}