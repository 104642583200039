<template>
<div>
  <header>
    <nav>
      <h1>
        <img class="coditas" src="https://media-exp1.licdn.com/dms/image/C4E0BAQGBdkXQH7vPHQ/company-logo_200_200/0/1636378103518?e=2147483647&v=beta&t=WNcJYopUBgAIMcmrnE6FjSGjziWHlhvV_mVwNKrbdKs" alt="Coditas Logo">
        Coditas Whatsapp Portal
      </h1>
      <ul>
        <!-- <li v-if="isLogin">
          <router-link to="/api/webhook">Webhook</router-link>
        </li> -->
        <li v-if="isLogin">
          <router-link to="/api/sendmessage">Candidates</router-link>
        </li>
        <li v-if="isLogin">
          <router-link to="/api/replies">Messages</router-link>
        </li>
        <li v-if="isLogin">
          <router-link to="/api/addcandidate">New Candidate</router-link>
        </li>
        <li v-if="isLogin">
          <router-link to="/api/edit">Team</router-link>
        </li>
        <li v-if="!isLogin">
          <router-link to="/login">
              <button @click="login">
                <img src="https://cdn-teams-slug.flaticon.com/google.jpg" alt="Google Image">Login 
              </button>
            </router-link>
        </li>
        <li v-else>
          <router-link to="/login"><button @click="logout">Logout</button></router-link>
        </li>
      </ul>
    </nav>
  </header>
  <va-alert color="#337df5" class="mb-4" v-if="message">
      {{message}}
  </va-alert>
</div>
</template>

<script>
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import "../firebaseConfig";

export default {
  props: ['chatSocket'],
  data() {
    return {
      isLogin: false,
      message: null,
      loggedInUser: {
        userName: "",
        email: "",
        token: null,
      }
    };
  },

  methods: {
    login() {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();

      signInWithPopup(auth, provider)
      .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      
      // The signed-in user info.
      const user = result.user;
      this.isLogin = true;
      this.message = "Login successfull!";
      this.$router.replace('/api/sendmessage');
      if (user){
          this.loggedInUser.userName = user.displayName;
          this.loggedInUser.email = user.email;
          this.loggedInUser.token = token;
          localStorage.setItem('loggedInUser', this.loggedInUser);
      }}).catch((error) => {
          this.message = "Login unsuccessfull! Please try again!!" || new Error(error);
      });
    },
    logout() {
        const auth = getAuth();
        signOut(auth).then(() => {
            // Sign-out successful.
            this.isLogin = false;
            this.message = "Logout sucessfull!";
            localStorage.removeItem('loggedInUser');
            localStorage.clear();
        }).catch((error) => {
             // An error happened.
            this.message = "Login unsuccessfull! Please try again!!" || new Error(error);
        });
    },
    checkUser() {
      const user = localStorage.getItem('loggedInUser');
      if(!user){
        this.isLogin = false;
      }
      else if(this.$route.name === '/login' && this.isLogin){
        this.$router.replace('/api/sendmessage');
      }
      else {
        this.isLogin = true;
        this.$router.replace('/api/sendmessage');
      }
    }
  },
  created() {
    setInterval(() => {
      this.message = null;
    }, 5000);
    this.checkUser();
  },
}
</script>


<style scoped>
div {
  margin: 0;
  padding: 0;
}

header {
  width: 100%;
  height: 5rem;
  background-color: #0a7ac4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

header a {
  text-decoration: none;
  color: #ffffff;
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
}

a:active,
a:hover,
a.router-link-active {
  border: 1px solid #ffffff;
  border-radius: 1rem;
  background-color: #024673;
}

h1 {
  margin: 0;
  font-size: 1.5rem;
  margin-left: 0;
  color: white;
}

h1 a {
  color: white;
  margin: 0;
}

h1 a:hover,
h1 a:active,
h1 a.router-link-active {
  border-color: transparent;
}

h1:hover {
  cursor: default;
}

header nav {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  margin: 0 0.5rem;
}

header {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.26);
}

button {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  color: #ffffff;
  font-weight: 600;
}

button:hover,
button:active {
  border: 1px solid #ffffff;
  border-radius: 1rem;
  background-color: #024673;
  border-color: transparent;
  cursor: pointer;
}

.va-alert {
  height: 2rem;
  min-width: 0%;
  width: 50%;
  align-content: center;
  margin-left: 25%;
  margin-top: 0.5rem;
}

img {
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
  margin-left: 0;
  margin-right: 0.5rem;
  border-radius: 1rem;
  margin-bottom: -0.2rem;
}
</style>
