<template>
    <base-card>
        <va-alert color="#337df5" class="mb-4" v-if="message">
            {{message}}
        </va-alert>
        <va-inner-loading :loading="isLoading" :size="60" color="primary">
        <div class="form-control">
            <div class="input">
                <va-input 
                    class="mb-4" 
                    v-model.trim="mobile"
                    :rules="[(v) => (v.length == 10 || success) || `Mobile No. must be 10 digits`]"
                    placeholder="Enter 10 digit mobile No" label="Mobile No"
                />
            </div>
            <div class="input">
                <va-input 
                    class="mb-4" 
                    v-model.trim="firstName"
                    :rules="[(v) => (v.length > 0 || success) || `First Name must not be empty.`]"
                    placeholder="Enter first name" label="First Name"
                />
                <va-input 
                    class="mb-4" 
                    v-model.trim="lastName"
                    :rules="[(v) => (v.length > 0 || success) || `Last Name must not be empty.`]"
                    placeholder="Enter last name" label="Last Name"
                />
                <div class="select">
                    <va-select 
                        v-model="selected_recruiter" 
                        :options="recruiters_list" 
                        label="Select Recruiter"
                        :rules="[(v) => ((v != '') || success) || `Must select recruiter`]"
                    />
                </div>
                <div class="select">
                    <va-select 
                        v-model="selected_tech_stack" 
                        :options="tech_stack_list" 
                        label="Select Tech Stack"
                        :rules="[(v) => ((v != '') || success) || `Must select tech stack`]"
                    />
                </div>
            </div>
        <va-button @click="addCandidate">Add Candidate</va-button>
        </div>
        </va-inner-loading>
    </base-card>
</template>

<script>
export default {
    data() {
        return {
            mobile: "",
            error: null,
            message: null,
            firstName: "",
            lastName: "",
            success: false,
            isLoading: false,
            counter: 0,
            recruiters_list: [],
            tech_stack_list: [],
            selected_recruiter: "",
            selected_tech_stack: "",
        };
    },
    methods: {
        async addCandidate() {
                if(this.mobile == "" || this.mobile.length != 10){
                    this.message = "Please enter mobile no.";
                    return;
                }
                else if(this.firstName == "" || this.lastName == ""){
                    this.message = "Please enter First Name and Last Name";
                    return;
                }
                else if(this.selected_recruiter == "" || this.selected_tech_stack == ""){
                    this.message = "Please select recuriter and Tech Stack";
                    return;
                }
                else {
                    this.isLoading = true;
                    const response = await fetch(process.env.VUE_APP_BASE_URL + "addcandidate", {
                        method: 'POST',
                        body: JSON.stringify({
                            mobile: this.mobile,
                            first_name : this.firstName,
                            last_name : this.lastName,
                            recruiter: this.selected_recruiter,
                            tech_stack: this.selected_tech_stack
                        })
                    });
                    const res_data  = await response.json();
                    this.isLoading = false;
                    const res_message = res_data.message;
                    if (!response.ok) {
                        this.error = new Error('Failed to send message.');
                        this.message = this.error;
                        return;
                    }
                    else if(res_message === (this.mobile +" is not valid.") & this.counter < 5){
                        this.addCandidate();
                        this.counter = this.counter + 1;
                    }
                    else {
                        this.message = res_message;
                        this.mobile = "";
                        this.firstName = "";
                        this.lastName = "";
                        this.success = true;
                        this.counter = 0;
                        this.selected_recruiter = "";
                        this.selected_tech_stack = "";
                    }
                }
                
        },
        async get_recruiter() {
            const response = await fetch(process.env.VUE_APP_BASE_URL + "editrecruiter");
            const res_data  = await response.json();
            const recruiters_data = res_data.recruiters_data;
            if (!response.ok) {
                this.error = new Error('Failed to send message.');
                this.message = this.error;
                return;
            }
            else {
                this.recruiters_list = recruiters_data;
            }
        },
        async get_tech_stack() {
            const response = await fetch(process.env.VUE_APP_BASE_URL + "edittechstack");
            const res_data  = await response.json();
            const tech_stack_data = res_data.tech_stack_data;
            if (!response.ok) {
                this.error = new Error('Failed to send message.');
                this.message = this.error;
                return;
            }
            else {
                this.tech_stack_list = tech_stack_data;
            }
        },
    },
    created() {
        setInterval(() => {
            this.message = null;
        },8000);
        this.get_recruiter();
        this.get_tech_stack();
    }
}
</script>

<style scoped>

.input {
    margin-top: 0.5rem;
}

.select {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

</style>