// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDUCDSiMaRCWHj1zOL8Q204aw2iwn3D2pQ",
  authDomain: "whatsapp-x-exotel-8cbe0.firebaseapp.com",
  projectId: "whatsapp-x-exotel-8cbe0",
  storageBucket: "whatsapp-x-exotel-8cbe0.appspot.com",
  messagingSenderId: "25225239574",
  appId: "1:25225239574:web:30a3a55ee7f4a7c57c2937"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;