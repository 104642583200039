<template>
    <div class="row">
        <div class="flex md6 lg4">
            <va-alert color="#337df5" class="mb-4" v-if="message">
                {{message}}
            </va-alert>
            <va-card>
                <h1 class="heading">Add new recruiter</h1>
                <va-input 
                    class="mb-4" 
                    v-model.trim="first_name"
                    :rules="[(v) => (v.length > 0 || success) || `First Name must not be empty.`]"
                    placeholder="Enter first name" label="Recruiter First Name"
                />
                <va-input 
                    class="mb-4" 
                    v-model.trim="last_name"
                    :rules="[(v) => (v.length > 0 || success) || `Last Name must not be empty.`]"
                    placeholder="Enter last name" label="Recruiter Last Name"
                />
                <va-button @click="add_recruiter()">Add Recruiter</va-button>
            </va-card>
            <va-card>
                <h1 class="heading">Add new Tech Stack</h1>
                <va-input 
                    class="mb-4" 
                    v-model.trim="tech_stack"
                    :rules="[(v) => (v.length > 0 || success) || `Tech stack must not be empty`]"
                    placeholder="Enter tech stack name" label="Tech Stack"
                />
                <va-button @click="add_tech_stack()">Add Tech Stack</va-button>
                </va-card>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            first_name: "",
            last_name: "",
            success: false,
            message: "",
            tech_stack: ""
        }
    },
    methods: {
        async add_recruiter() {
            if(this.first_name == "" || this.last_name == ""){
                this.message = "Please enter First Name and Last Name";
                return;
            }
            else {
                const response = await fetch(process.env.VUE_APP_BASE_URL + "editrecruiter", {
                    method: 'POST',
                    body: JSON.stringify({
                        first_name : this.first_name,
                        last_name : this.last_name,
                    })
                });
                const res_data  = await response.json();
                this.isLoading = false;
                const res_message = res_data.message;
                if (!response.ok) {
                    this.error = new Error('Failed to send message.');
                    this.message = this.error;
                    return;
                }
                else {
                    this.success = true;
                    this.first_name = "";
                    this.last_name = "";
                    this.message = res_message;
                }
            }
        },
        async add_tech_stack() {
            if(this.tech_stack == ""){
                this.message = "Please enter tech stack";
                return;
            }
            else {
                const response = await fetch(process.env.VUE_APP_BASE_URL + "edittechstack", {
                    method: 'POST',
                    body: JSON.stringify({
                        tech_stack : this.tech_stack
                    })
                });
                const res_data  = await response.json();
                this.isLoading = false;
                const res_message = res_data.message;
                if (!response.ok) {
                    this.error = new Error('Failed to send message.');
                    this.message = this.error;
                    return;
                }
                else {
                    this.success = true;
                    this.tech_stack = "";
                    this.message = res_message;
                }
            }
        }
    },
    created() {
        setInterval(() => {
            this.message = null;
        },8000);
    }
}
</script>

<style scoped>

.row {
    align-content: center;
    justify-content: center;
    max-height: 100%;
}

.va-card {
    padding: 1rem;
    margin-top: 1rem;
}

.heading {
    color: #0a7ac4;
}

</style>