<template>
    <base-card>
        <h1>Welcome to Whatsapp X Exotel</h1>
        <h4>Click on login with google to continue.</h4>
    </base-card>
</template>

<style scoped>
h1 h4 {
    align-self: center;
    color: #ffffff;
    margin: 1rem;
}

</style>