<template>
    <body>
		<div class="wrap">
			<section class="left">
				<div class="contact-list">
                    <h1>Candidates</h1>
                    <va-alert color="#337df5" class="mb-4 alert" v-if="alert">
                        {{alert}}
                    </va-alert>
                    <div class="select-container">
                        <div class="select-recruiter">
                            <va-select 
                                v-model="selected_recruiter" 
                                :options="recruiters_list" 
                                label="Select Recruiter"
                            />
                        </div>
                        <div class="select-tech-stack">
                            <va-select 
                                v-model="selected_tech_stack" 
                                :options="tech_stack_list" 
                                label="Select Tech Stack"
                            />
                        </div>
                            <va-button 
                                icon="search" 
                                class="mr-4 mb-2"
                                @click="candidate_filter"
                            >
                            </va-button>
                    </div>
                    <va-inner-loading :loading="isLoading" :size="60" color="primary">
                    <va-list>  
                        <va-list-item v-for="(candidate, index) in candidates" :key="index" @click="get_replies(candidate.phone_no, candidate.name, candidate.unread_count)">
                            <va-list-item-section avatar>
                                <va-avatar class="mr-4">{{ candidate.name[0] }}</va-avatar>
                            </va-list-item-section>
                            <va-list-item-section id="name">
                                <va-list-item-label>{{ candidate.name }} </va-list-item-label>
                                <va-list-item-label caption>
                                    {{ candidate.recruiter }} - {{ candidate.tech_stack }} 
                                </va-list-item-label>
                            </va-list-item-section>
                            <va-list-item-section icon>
                                <va-badge v-if="(candidate.unread_count > 0)" v-text="candidate.unread_count" color="primary" />
                            </va-list-item-section>
                        </va-list-item>
                    </va-list>
                    </va-inner-loading>
                </div>
			</section>
			<section class="right">
				<div class="chat-head">
                    <div v-if="message_object.length != 0" class="chat-head-child">
                        <va-avatar class="head-icon">{{message_object[0][0]}}</va-avatar>
                        <span class="message-name">{{message_object[0]}}</span>
                        <div class="select">
                            <va-select 
                                v-model="selected_template" 
                                :options="template_messages" 
                                label="Select Template Message"
                                :rules="[(v) => ((v != '') || success) || `Must select Template`]"
                            />
                        </div>
                        <va-button 
                            icon="send" 
                            class="mr-4 mb-2 template-button"
                            @click="sendTemplateMessage(phone_no, name)"
                        >
                        </va-button>
                    </div>
                </div>
				<div class="wrap-chat">
					<div class="chat">
                        <p class="no-messages" v-if="!selected">Please select a candidate</p>
                        <div v-for="(messages, index) in message_object" :key="index">
                            <p class="no-messages" v-if="message_object.length === 1">
                                No Messages Yet
                            </p>
                            <span 
                                class="date"
                                v-if="(index >= 1)">
                                <b>{{messages.time.slice(0, 10)}}</b>
                            </span>
                            <div class="your-mouth" v-if="(index != 0) && (!messages.is_your_msg)">
                                <div class="namediv">
                                    <p class="chat-name">{{messages.name.split(" ")[0]}}</p>
                                </div>
                                <p class="content">{{messages.message}}</p>
                                <div class="timediv">
                                    <span class="time">
                                        {{messages.time.slice(11, 16)}}
                                    </span>
                                </div>
                            </div>
                            <div class="my-parent-div">
                                <div class="my-mouth" v-if="messages.is_your_msg">
                                    <div class="namediv">
                                        <p class="chat-name">You</p>
                                    </div>
                                    <p class="content">{{messages.message}}</p>
                                    <div class="timediv">
                                        <span class="time">
                                            {{messages.time.slice(11, 16)}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="no-messages" v-if="warning && selected">If no communication since last 24 hours then try to send message using send message tab.</p>
                        <base-card v-if="success">Try to send message using send message first.</base-card>
                    </div>
				</div>
					<div class="wrap-message" v-if="selected">
                        <div class="file">
                            <va-file-upload 
                                v-model="upload_file" 
                                file-types="pdf"
                                icon="attach_file"
                            />
                            <va-button 
                                class="send-file" 
                                @click="send_file(phone_no, name)" 
                                icon="arrow_forward"
                                >
                            </va-button>
                        </div>
						<div class="message">
							<va-input
                                class="mb-4"
                                v-model.trim="message_body"
                                type="textarea"
                                :placeholder="msg"
                                autosize
                            />
						</div>
                        <va-button 
                            icon="send" 
                            class="mr-4 mb-2"
                            @click="sendMessage(phone_no, name)"
                        >
                        </va-button>
					</div>
			</section>
		</div>
    </body>
</template>

<script>
import candidateListMixin from "../mixins.js";
// import chatSocketObj from "../App.vue";

export default {
    mixins: [candidateListMixin],
    data() {
        return {
            message_object:[],
            error: null,
            candidates: [],
            all_candidates: [],
            selected: false,
            success: "",
            phone_no: "",
            message: "",
            alert: "",
            name: "",
            msg: "Type here to send message...",
            warning: true,
            template_messages: ['First Conversation Message', 'Get Candidate Details','Not looking for job change', 'Follow Up Message', 'Details Received Relavent', 'Rejection Message', 'ELTP Selected', 'ELTP Rejected', 'Coditas Infographics 1', 'Laterals Infographic', 'ELTP Infographic'],
            recruiters_list: [],
            tech_stack_list: [],
            selected_recruiter: "",
            selected_tech_stack: "",
            selected_candidates: ["",],
            selected_template: "",
            isLoading: false,
            upload_file: [],
            message_body: "",
        };
    },
    inject: ['chatSocketObj'],
    methods: {
        async get_replies(number, name, unread_count) {
            this.phone_no = number;
            this.flag = false;
            this.name = name;
            this.message = "";
            this.message_object = [];
            const response = await fetch(process.env.VUE_APP_BASE_URL + "seereplies", {
                method: 'POST',
                body: JSON.stringify({
                    phone_no: number
                })
            });
            const response_data = await response.json();
            const messages_response = response_data['messages_response'];
            if (!response.ok) {
                this.error = new Error('Failed to fetch replies.');
                return;
            }
            else{
                if(unread_count>0){
                this.candidate_filter();
                }
                this.message_object.push(name);
                for(var i = 0; i < messages_response.length; i++){
                    this.message_object.push(messages_response[i]);
                }
                this.selected = true;
                localStorage.setItem('selected', this.selected);
            }
        },
        async sendTemplateMessage(phone_no, name) {
            this.selected_candidates[0] = phone_no;
            const response = await fetch(process.env.VUE_APP_BASE_URL + "sendmessage", {
                method: 'POST',
                body: JSON.stringify({
                    selected_candidates: this.selected_candidates,
                    template_name: this.selected_template
                })
            });
            const res_data  = await response.json();
            const res_message = res_data.message;
            if (!response.ok) {
                this.error = new Error('Failed to send message.');
                return;
            }
            else{
                this.message = res_message;
                this.selected_candidates[0] = "";
                this.selected_template = "";
                this.get_replies(phone_no, name);
            }            
        },
        async sendMessage(phone_no, name) {
            if(this.message_body === ""){
                this.msg = "Can't send empty message!"
                return;
            }
            this.success = "";
            const response = await fetch(process.env.VUE_APP_BASE_URL + "messagecustom", {
                method: 'POST',
                body: JSON.stringify({
                    phone_no: phone_no,
                    message: this.message_body
                })
            });
            if (!response.ok) {
                this.error = new Error('Failed to send message.');
                this.message_body = "";
                this.success = "Try to send using send message first..."
                return;
            }
            else{
                this.message_body = "";
                this.get_replies(phone_no, name);
            }
            return 0;
        },
        async candidate_filter() {
            this.candidates = [];
            if(this.recruiter === "" & this.tech_stack === ""){
                this.message = "Please select at least recruiter or tech_stack";
                return; 
            }
            else{
                this.isLoading = true;
                const response = await fetch(process.env.VUE_APP_BASE_URL + "getcandidates", {
                    method: 'POST',
                    body: JSON.stringify({
                        recruiter: this.selected_recruiter,
                        tech_stack: this.selected_tech_stack
                    })
                });
                const res_data  = await response.json();
                this.isLoading = false;
                if (!response.ok) {
                    this.error = new Error('Failed to fetch candidates');
                    return;
                }
                else if(res_data.length === 0){
                    this.alert = "No candidates for given selection";
                    return;
                }
                else{
                    for(var i = 0; i < res_data.length; i++){
                        this.candidates.push(res_data[i]);
                    }
                }
            }
        },
        async send_file(phone_no, name){
            if(this.upload_file.length === 0){
                this.message = "Please select at least one file";
                return;
            }
            else{
                var file_data = new FormData();
                file_data.append('file', this.upload_file[0]); 
                file_data.append('phone_no', phone_no);
                this.isLoading = true;
                const response = await fetch(process.env.VUE_APP_BASE_URL + "sendfile", {
                    method: 'POST',
                    body: file_data
                });
                const res_data  = await response.json();
                this.isLoading = false;
                if (!response.ok) {
                    this.error = new Error('Failed to send file');
                    return;
                }
                else {
                    this.upload_file = [];
                    this.get_replies(phone_no, name);
                    this.message = res_data["message"];
                }
            }
        },
        async get_recruiter() {
            const response = await fetch(process.env.VUE_APP_BASE_URL + "editrecruiter");
            const res_data  = await response.json();
            const recruiters_data = res_data.recruiters_data;
            if (!response.ok) {
                this.error = new Error('Failed to send message.');
                this.message = this.error;
                return;
            }
            else {
                this.recruiters_list = recruiters_data;
            }
        },
        async get_tech_stack() {
            const response = await fetch(process.env.VUE_APP_BASE_URL + "edittechstack");
            const res_data  = await response.json();
            const tech_stack_data = res_data.tech_stack_data;
            if (!response.ok) {
                this.error = new Error('Failed to send message.');
                this.message = this.error;
                return;
            }
            else {
                this.tech_stack_list = tech_stack_data;
            }
        },
        chat_socket_function() {
        },
    },
    created() {
        this.selected = false;
        if(localStorage.getItem('selected')){
            this.selected = true;
        }
        setInterval(() => {
            this.msg = "Type here to send message...";
            this.error = null;
            this.success = "";
            this.warning = false;
            this.alert = "";
            this.message = "";
        }, 8000);
        this.get_recruiter();
        this.get_tech_stack();
        const that = this;
        this.chatSocketObj.onopen = function () {
            console.log("Websocket connected");
        };
        this.chatSocketObj.onmessage = function (event) {
            const data = JSON.parse(event.data);
            console.log("Event from websocket:", data.message);
            that.candidate_filter();
        };
        this.chatSocketObj.onclose = function () {
            console.error("Chat socket closed unexpectedly");
        };
    },
    unmounted() {
        this.selected = false;
        localStorage.removeItem('selected');
        this.warning = false;
        this.selected_recruiter = "";
        this.selected_tech_stack = "";
    }
}
</script>

<style scoped>
/* ------ GENERAl LAYOUT ------ */
* {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background: #dbdbdb;
    margin: 0;
    padding: 0;
}

/* ------ FONTS ------ */
.font-name {
    color: #000000;
    font-size: 1em;
    font-weight: inherit;
    padding-bottom: 3px;
}

.wrap {
    display: flex;
    height: 100vh;
    width: 100%;
    max-height: calc(100vh - 5rem);
    margin: 0;
    border: thick solid #0a7ac4;
}

/* ------ LEFT SIDE ------ */
.left {
    width: 30%;
}

.contact-list {
    background-color: white;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border-right: thick solid #0a7ac4;
}

.va-list {
    width: 90%;
    margin-left: 0.5rem;
    max-height: 350px;
    overflow: auto;
}

h1 {
    font-size: 1.5rem;
    margin-top: 1rem;
    color: #175dcf;
}

.va-list-item {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 0.5rem;
    margin: 0.5rem;
}

.va-list-item:hover {
    cursor: pointer;
    background: #b1dffc;
    z-index: 200;
}

.icon {
    color: white;
    background: red;
    size: 5px;
    border-radius: 2px;
}

.va-badge {
    background: #175dcf;
    padding: 0.5rem;
    color: white;
    border-radius: 1rem;
}

.select-container {
    margin: 0.5rem;
    max-width: 400px;
}

.select-recruiter {
    margin: 1rem;
}

.select-tech-stack {
    margin: 1rem;
}


/* ------ RIGHT SIDE ------ */
.right {
    width: 75%;
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1089577/background.png);
}

.chat-head {
    background-color: white;
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: flex-start;
    box-shadow: 0 2px 2px rgba(0,0,0,0.05);
}

.chat-head-child {
    background-color: white;
    width: 100%;
    height: inherit;
    display: flex;
    align-items: flex-start;
    padding: 0.25rem;
    box-shadow: 0 2px 2px rgba(0,0,0,0.05);
}

.head-icon {
    margin-left: 1rem;
    margin-top: 0.2rem;
    align-self: flex-start;
}

.message-name {
    color: #EE33AA;
    margin-left: 1rem;
    margin-top: 1rem;
    font-weight: bold;
    align-self: flex-start;
}

.select {
    max-width: 300px;
    align-self: flex-end;
    justify-self: flex-start;
    margin-left: 20rem;
    margin-bottom: 0.5rem;
}

.template-button {
    align-self: flex-end;
    margin-left: 3rem;
}

.wrap-chat {
    height: calc(100% - 120px);
    display: flex;
}

.chat {
    background: transparent;
    width: 100%;
    padding: 2.5%;
    padding-top: 1rem;
    overflow-y: auto;
}

/* ------ CHAT ------ */

.your-mouth {
    min-width: 0;
    min-height: 0;
    max-width: fit-content;
    padding: 0.5rem;
    margin: 0.7rem;
    background: #f2fbfc;
    border: thin solid transparent;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    overflow-wrap: break-word;
    white-space: pre-wrap;
    z-index: 200;
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.my-parent-div {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.my-mouth {
    min-width: 0;
    min-height: 0;
    max-width: fit-content;
    padding: 0.5rem;
    margin: 0.7rem;
    background: #dfe7e8;
    border: thin solid transparent;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    overflow-wrap: break-word;
    white-space: pre-wrap;
    z-index: 200;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.no-messages {
    min-width: 0%;
    min-height: 0%;
    width: 8rem;
    background: #363535;
    color: #ebebeb;
    font-size: 0.8rem;
    border: thin solid transparent;
    border-radius: 0.5rem;
    padding: 0;
    margin-left: 40%;
    align-self: center;
    margin-top: 1rem;
}

.important {
    background: #ebebeb;
    display: block;
    margin-left: 40%;
    align-self: center;
    margin-top: 1rem;
    font-size: 0.8rem;
    padding: 0;
    border: thin solid transparent;
    border-radius: 0.5rem;
}

.content {
    line-height: 120%;
    font-size: 0.9em;
}

.namediv {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.chat-name {
    color: #069a5a;
    font-size: 0.7rem;
    margin-left: 0;
    margin-top: 0;
    left: 0;
    max-width: 4rem;
}

.va-avatar .head-icon {
    margin-left: 1rem;
    margin-top: 0.2rem;
}

.message-name {
    color: #EE33AA;
    margin-left: 1rem;
    margin-top: 1rem;
    font-weight: bold;
}

.timediv {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.time {
    color: rgba(0,0,0,0.4);
    font-size: 0.6em;
    text-align: right;
    text-decoration: underline;
}

.date {
    font-size: 0.7rem;
    background: whitesmoke;
    color: #3f544d;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.wrap-message {
    width: 100%;
    height: 3rem;
    background: transparent;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.va-alert {
    padding: 1rem;
    margin: 2rem;
    width: 100;
    height: 2rem;
}

.message {
    width: 100%;
    height: 2rem;
    max-height: 3rem;
    background: transparent;
    margin: auto;
    border-radius: 5px;
    align-self: flex-end;
    justify-self: flex-start;
}

.va-input {
    width: 100%;
    margin: 0.2rem 0.2rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    height: auto;
    resize: none;
    max-height: 3rem;
    overflow-y: auto;
}

.va-button {
    padding: 1.5rem;
    margin-left: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.file {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    /* margin-top: -2.5rem; */
    margin-bottom: 0;
}

.send-file {
    padding: 0;
}

.base-card {
    background: #028acf;
    color: white;
}
/* ------ SCROLLBAR ------ */

body::-webkit-scrollbar, .va-list::-webkit-scrollbar, .chat::-webkit-scrollbar, .information::-webkit-scrollbar, .va-input::-webkit-scrollbar {
    width: 0.4em;
    height: 0.4em;
}

body::-webkit-scrollbar-thumb, .va::-webkit-scrollbar-thumb, .chat::-webkit-scrollbar-thumb, .va-input::-webkit-scrollbar-thumb{
    background-color: rgba(0,0,0,0.2);
}

/* ------ MEDIA QUERIES ------ */
@media (min-width: 1200px) {
    .wrap {
        margin: 0;
        max-height: 100%;
    }
}
</style>
