<template>
    <div class="row">
        <div class="flex md6 lg4">
        <va-card>
            <h1>Candidates</h1>
            <va-alert color="#337df5" class="mb-4" v-if="message">
                {{message}}
            </va-alert>
            <div class="select-container">
                <div class="select-recruiter">
                    <va-select 
                        v-model="selected_recruiter" 
                        :options="recruiters_list" 
                        label="Select Recruiter"
                    />
                </div>
                <div class="select-tech-stackr">
                    <va-select 
                        v-model="selected_tech_stack" 
                        :options="tech_stack_list" 
                        label="Select Tech Stack"
                    />
                </div>
                    <va-button 
                        icon="search" 
                        class="mr-4 mb-2"
                        @click="candidate_filter"
                    >
                    </va-button>
                </div>
            <va-inner-loading :loading="isLoading" :size="60" color="primary">
            <va-list>
                <va-list-item v-for="(candidate, index) in candidates" :key="index">
                    <va-list-item-section avatar>
                        <va-avatar class="mr-4">{{ candidate.name[0] }}</va-avatar>
                    </va-list-item-section>
                    <va-list-item-section>
                        <va-list-item-label>{{ candidate.name }} </va-list-item-label>
                        <va-list-item-label caption>
                            {{ candidate.recruiter }} - {{ candidate.tech_stack }} 
                        </va-list-item-label>
                    </va-list-item-section>
                    <va-list-item-section icon>
                        <input type="checkbox" :value="candidate.phone_no" @blur="selected">
                    </va-list-item-section>
                </va-list-item>
            </va-list>
            </va-inner-loading>
                <div class="select_template">
                    <va-select 
                        v-model="selected_template" 
                        :options="template_messages" 
                        label="Select Template Message"
                        :rules="[(v) => ((v != '') || success) || `Must select Template`]"
                    />
                </div>
            <va-button @click="sendMessage">Send Message</va-button>
            <va-popover  class="mr-2 mb-2" color="primary" :message="popover_message">
                <va-button @click="delteCandidate" class="delete">Delete Candidates</va-button>
            </va-popover>
        </va-card>
        
        </div>
    </div>
</template>

<script>
import candidateListMixin from "../mixins.js";
export default {
    mixins: [candidateListMixin],
    data() {
        return {
            candidates : [],
            selected_candidates: [],
            template_messages: ['First Conversation Message', 'Get Candidate Details','Not looking for job change', 'Follow Up Message', 'Details Received Relavent', 'Rejection Message', 'ELTP Selected', 'ELTP Rejected', 'Coditas Infographics 1', 'Laterals Infographic', 'ELTP Infographic'],
            recruiters_list: [],
            tech_stack_list: [],
            selected_template: "",
            value: false,
            message: null,
            error: null,
            isLoading: false,
            popover_message: "All the communication/messages to candidate will be deleted",
            selected_recruiter: "",
            selected_tech_stack: "",
            success: false,
        }
    },
    methods: {
        selected(event) {
            if(event.target.checked){
                this.selected_candidates.push(event.target.value);
            }
        },
        async sendMessage() {
            this.candidates = [];
            if(this.selected_candidates.length === 0) {
                this.message = "Please select at least one candidate";
                this.candidate_filter();
            }
            else {
                this.isLoading = true;
                const response = await fetch(process.env.VUE_APP_BASE_URL + "sendmessage", {
                    method: 'POST',
                    body: JSON.stringify({
                        selected_candidates: this.selected_candidates,
                        template_name: this.selected_template
                    })
                });
                const res_data  = await response.json();
                this.isLoading = false;
                const res_message = res_data.message;
                if (!response.ok) {
                    this.error = new Error('Failed to send message.');
                    return;
                }
                else{
                    this.message = res_message;
                    this.selected_candidates = [];
                    this.selected_template = "";
                    this.candidate_filter();
                    this.success = true;
                }
            }
        },
        async delteCandidate() {
            this.candidates = [];
            if(this.selected_candidates.length === 0) {
                this.message = "Please select at least one candidate";
                this.candidate_filter();
            }
            else {
                this.isLoading = true;
                const response = await fetch(process.env.VUE_APP_BASE_URL + "deletecandidate", {
                    method: 'POST',
                    body: JSON.stringify({
                        selected_candidates: this.selected_candidates
                    })
                });
                const res_data  = await response.json();
                this.isLoading = false;
                const res_message = res_data.message;
                if (!response.ok) {
                    this.error = new Error('Failed to delete candidates.');
                    return;
                }
                else{
                    this.message = res_message;
                    this.selected_candidates = [];
                    this.candidate_filter();
                    this.success = true;
                }
            }
        },
        async candidate_filter() {
            this.candidates = [];
            if(this.recruiter === "" & this.tech_stack === ""){
                this.message = "Please select at least recruiter or tech stack";
                return; 
            }
            else{
                this.isLoading = true;
                const response = await fetch(process.env.VUE_APP_BASE_URL + "getcandidates", {
                    method: 'POST',
                    body: JSON.stringify({
                        recruiter: this.selected_recruiter,
                        tech_stack: this.selected_tech_stack
                    })
                });
                const res_data  = await response.json();
                this.isLoading = false;
                if (!response.ok) {
                    this.error = new Error('Failed to fetch candidates');
                    return;
                }
                else if(res_data.length === 0){
                    this.message = "Sorry can't get candidates for given selection";
                    return;
                }
                else{
                    for(var i = 0; i < res_data.length; i++){
                        this.candidates.push(res_data[i]);
                    }
                }
            }
        },
        async get_recruiter() {
            const response = await fetch(process.env.VUE_APP_BASE_URL + "editrecruiter");
            const res_data  = await response.json();
            const recruiters_data = res_data.recruiters_data;
            if (!response.ok) {
                this.error = new Error('Failed to send message.');
                this.message = this.error;
                return;
            }
            else {
                this.recruiters_list = recruiters_data;
            }
        },
        async get_tech_stack() {
            const response = await fetch(process.env.VUE_APP_BASE_URL + "edittechstack");
            const res_data  = await response.json();
            const tech_stack_data = res_data.tech_stack_data;
            if (!response.ok) {
                this.error = new Error('Failed to send message.');
                this.message = this.error;
                return;
            }
            else {
                this.tech_stack_list = tech_stack_data;
            }
        },
    },
    created () {
        setInterval(() => {
            this.message = null;
        }, 7000);
        this.get_recruiter();
        this.get_tech_stack();
    },
    unmounted() {
        this.selected_tech_stack = "";
        this.selected_recruiter = "";
    }
}
</script>

<style scoped>
.row {
    align-content: center;
    justify-content: center;
    max-height: 100%;
}

h1 {
    font-size: 1.5rem;
    color: #175dcf;
    padding: 1rem;
}

.error {
    font-weight: bold;
    color: red;
    font-style: italic;
    margin-bottom: 1rem;
}

.va-list {
    height: 350px;
    overflow-y: auto ;
    margin-top: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.va-list-item {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 0.5rem;
    margin: 0.5rem;
}

input {
    cursor: pointer;
    height: 1rem;
    width: 1rem;
}

.va-button {
    margin: 1rem;
    border-color: #013a22;
    background-color: #d3fbd6;
    margin-top: 0;
}

.va-button:hover {
    color: #ffffff;
    background-color: #013a22;
    border-color: #ffffff;
    cursor: pointer;
}

.delete {
    margin-left: 0;
}

.va-alert {
    width: 100%;
}

.va-card {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 0rem 3rem;
    width: 120%;
    max-height: 100%;
}

.select-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 120%;
    margin-left: -1.5rem;
}

.select-recruiter {
    max-width: 400px;
    margin-right: 1rem;
    display: inline;
    align-self: flex-start;
    justify-self: flex-start;
}

.select-tech-stack {
    display: inline;
    align-self: flex-end;
    justify-content: flex-end;
}

.select_template {
    max-width: 300px;
    margin-bottom: 1rem;
    margin-left: 3rem;
    margin-top: 0.5rem;
}

.va-select {
    margin-bottom: 2rem;
}

.va-list::-webkit-scrollbar {
    height: 0.4em;
    width: 0.4em;
}

.va-list::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
}
</style>