import { createRouter, createWebHistory } from 'vue-router';

// import SetWebhook from './components/SetWebhook.vue';
import SendMessage from './components/SendMessage.vue';
import AddCandidate from './components/AddCandidate.vue';
import LoginUI from './components/LoginUI.vue';
import NotFound from './components/NotFound.vue';
import data from './components/TheHeader.vue';
import MessageUI from './components/MessageUI.vue';
import EditOptions from './components/EditOptions.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', redirect: '/login'},
        { path: '/login', component: LoginUI},
        // { path: '/api/webhook', component: SetWebhook, meta: { requiresAuth: true }},
        { path: '/api/sendmessage', component: SendMessage, meta: { requiresAuth: true }},
        { path: '/api/addcandidate', component: AddCandidate, meta: { requiresAuth: true }},
        { path: '/api/replies', component: MessageUI, meta: { requiresAuth: true }},
        { path: '/api/edit', component: EditOptions, meta: { requiresAuth: true }},
        { path: '/:notFound(.*)', component: NotFound },
        { path: '/api/:notFound(.*)', component: NotFound},
    ]
});

let loginVar = data.isLogin;
router.beforeEach(function(to, _, next) {
    if(to.meta.requiresAuth && !loginVar) {
        next('/login');
        loginVar = true;
    } else {
        next();
    }
});
export default router;